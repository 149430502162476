import {connex} from "../../constants/contract";
import {ADDRESS_KEY, PROJECT_NAME} from "../../constants";

export const handleLogIn = async () => {
    connex
        .vendor
        .sign('cert', {
            purpose: 'identification',
            payload: {
                type: 'text',
                content: 'Please sign the certificate to connect to ' + PROJECT_NAME
            }
        })
        .request()
        .then(result => {
            localStorage.setItem(ADDRESS_KEY, result.annex.signer);
            document.location.href = "/";
        })
};

export const handleLogout = () => {
    localStorage.removeItem(ADDRESS_KEY)
    document.location.href = "/";
}
