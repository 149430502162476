import {useEffect, useState} from 'react';
import {veSharksNftContract} from "../constants/contract"
import {veSharksNftAbi} from "../constants/veSharksNftAbi";
import {NftContract} from "../components/functions/NftContract";
import {getMetadata} from "../components/functions/NftRarityTool";
import {Loader} from "../components/functions/Loader";
// import {closeModal, handleAttributesClick, handleTransferClick} from "../components/functions/ModelFunctionality";
import {USER_ADDRESS} from "../constants";
import menu from '../images/menu.png'
import {handleLogIn, handleLogout} from "../components/functions/AccountFuntionality";
import logo from "../images/Logoooo.png";
import {closeModal, handleAttributesClick, handleTransferClick} from "../components/functions/ModelFunctionality";

const Assets = () => {
    const [metadata, setMetadata] = useState([]);
    const [recipient, setRecipient] = useState('');
    const [balanceOf, setBalanceOf] = useState(0)
    const [attributesModelData, setAttributesModelData] = useState([]);
    const [transferModelData, setTransferModelData] = useState(null);
    const [nftContract] = useState(new NftContract());
    const [loading, setLoading] = useState(false)

    useEffect(async () => {
        await initialize();
    }, []);

    const initialize = async () => {
        if (USER_ADDRESS) {
            await loadAssets();
        }


    }

    const loadAssets = async () => {
        const walletOfOwner = await nftContract.walletOfOwner(veSharksNftAbi, veSharksNftContract);
        setBalanceOf(walletOfOwner.length)
        const metas = await getMetadata(walletOfOwner, 'vesharks')
        setMetadata([...metas])

    }

    import('./../Assets.css')
    return <div>
        <header className="u-clearfix u-header u-palette-2-base">
            <div className="u-clearfix u-sheet u-sheet-1">
                <nav className="u-menu u-menu-dropdown u-offcanvas u-menu-1 u-enable-responsive" data-responsive-from="XL">
                    <div className="menu-collapse" style={{ fontSize: '1rem', letterSpacing: '0px' }}>
                        <a className="u-button-style u-custom-left-right-menu-spacing u-custom-padding-bottom u-custom-text-active-color u-custom-text-color u-custom-text-hover-color u-custom-top-bottom-menu-spacing u-file-icon u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base u-text-white u-file-icon-1"
                           href="#">
                            <img src={menu}/>
                        </a>
                    </div>
                    <div className="u-nav-container">
                        <ul className="u-nav u-unstyled u-nav-1">
                            <li className="u-nav-item"><a
                                className="u-button-style u-nav-link u-text-active-white u-text-body-alt-color u-text-hover-white"
                                href="/" style={{padding: '10px 20px'}}>Home</a>
                            </li>
                            <li className="u-nav-item"><a
                                className="u-button-style u-nav-link u-text-active-white u-text-body-alt-color u-text-hover-white active"
                                href="./my-sharks" style={{padding: '10px 20px'}}>My Sharks</a>
                            </li>
                            <li className="u-nav-item"><a
                                className="u-button-style u-nav-link u-text-active-white u-text-body-alt-color u-text-hover-white"
                                href="./minting" style={{padding: '10px 20px'}}>Minting Page</a>
                            </li>
                        </ul>
                    </div>
                    <div className="u-nav-container-collapse">
                        <div
                            className="u-black u-container-style u-inner-container-layout u-opacity u-opacity-95 u-sidenav">
                            <div className="u-inner-container-layout u-sidenav-overflow">
                                <div className="u-menu-close"></div>
                                <ul className="u-align-center u-nav u-popupmenu-items u-unstyled u-nav-2">
                                    <li className="u-nav-item"><a className="u-button-style u-nav-link"
                                                                  href="./">Home</a>
                                    </li>
                                    <li className="u-nav-item"><a className="u-button-style u-nav-link active"
                                                                  href="./my-sharks">My Sharks</a>
                                    </li>
                                    <li className="u-nav-item"><a className="u-button-style u-nav-link"
                                                                  href="./minting">Minting Page</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="u-black u-menu-overlay u-opacity u-opacity-70"></div>
                    </div>
                    <style className="offcanvas-style"> {`.u-offcanvas
                    .u-sidenav {flex - basis: 250px !important;} .u-offcanvas:not(.u-menu-open-right)
                    .u-sidenav {margin - left: -250px;} .u-offcanvas.u-menu-open-right
                    .u-sidenav {margin - right: -250px;} @keyframes
                    menu-shift-left {from {left: 0;} to {left: 250px;}} @keyframes
                    menu-unshift-left {from {left: 250px;} to {left: 0;}} @keyframes
                    menu-shift-right {from {right: 0;} to {right: 250px;}} @keyframes
                    menu-unshift-right {from {right: 250px;} to {right: 0;}}`}            </style>
                </nav>
                {USER_ADDRESS === null ?
                    <button onClick={handleLogIn} className="u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-1">Connect Wallet</button> :
                    <button onClick={handleLogout} className="u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-1">{USER_ADDRESS.substr(0,4)}...{USER_ADDRESS.substr(38, 4)}</button> }

            </div>
        </header>
        <section className="u-clearfix u-palette-2-dark-2 u-section-1" id="sec-7a66">
            <div className="u-clearfix u-sheet u-sheet-1">
                <h1 className="u-custom-font u-text u-text-body-alt-color u-text-default u-title u-text-1">Your
                    Sharks</h1>
                <h5 className="u-custom-font u-text u-text-body-alt-color u-text-default u-text-2">Total Sharks: {balanceOf}</h5>
                <div className="u-expanded-width u-list u-list-1">
                    <div className="u-repeater u-repeater-1">
                        {metadata.map((token, index) =>
                        <div key={index}
                            className="u-border-2 u-border-custom-color-4 u-container-style u-gradient u-list-item u-radius-30 u-repeater-item u-shape-round u-video-cover u-list-item-1">
                            <div className="u-container-layout u-similar-container u-container-layout-1">
                                <h3 className="u-custom-font u-text u-text-default u-text-3">#{token.result.edition}</h3>
                                <div
                                    className="u-border-4 u-border-custom-color-1 u-line u-line-horizontal u-line-1"></div>
                                <h6 className="u-custom-font u-text u-text-default u-text-4">Rank : {token.result.attributes[token.result.attributes.length - 2].value}</h6>
                                <img alt="" className="u-image u-image-default u-image-1" data-image-width="1000"
                                     data-image-height="1000" src={token.img} />
                                    <p className="u-align-center u-custom-font u-font-oswald u-text u-text-default u-text-5">
                                        <a className="u-active-none u-border-none u-btn u-button-link u-button-style u-custom-font u-dialog-link u-hover-none u-none u-text-black u-btn-1"
                                           href="#sec-2622" onClick={() => handleAttributesClick(token, setAttributesModelData)}> Attributes</a>
                                    </p>
                                    <a href="#carousel_81cb" onClick={() => handleTransferClick(index, 'carousel_81cb', setTransferModelData)}
                                       className="u-border-none u-btn u-btn-round u-button-style u-custom-color-2 u-custom-font u-dialog-link u-hover-palette-4-dark-3 u-radius-50 u-btn-2">Transfer</a>
                            </div>
                        </div>
                            )}
                    </div>
                </div>
            </div>
        </section>
        <section
            className="u-black u-clearfix u-container-style u-dialog-block u-opacity u-opacity-70 u-dialog-section-4"
            id="sec-2622">
            <div
                className="u-border-4 u-border-palette-2-dark-1 u-container-style u-dialog u-palette-2-light-2 u-radius-26 u-shape-round u-dialog-1">
                <div className="u-container-layout u-valign-bottom u-container-layout-1">
                    <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
                        <div className="u-layout">
                            <div className="u-layout-row">
                                <div className="u-container-style u-layout-cell u-size-30 u-layout-cell-1">
                                    <div className="u-container-layout u-valign-top u-container-layout-2">
                                        {attributesModelData.result ? <h3 className="u-custom-font u-font-open-sans u-text u-text-default u-text-1">ID #{attributesModelData.result.edition}</h3> : null }
                                        {attributesModelData.result ? <img className="u-image u-image-default u-preserve-proportions u-image-1" src={attributesModelData.img} alt="" data-image-width="1000" data-image-height="1000" /> : null }
                                        {attributesModelData.result ? <h6 className="u-custom-font u-font-open-sans u-text u-text-default u-text-2">Rarity: {attributesModelData.result.attributes[attributesModelData.result.attributes.length - 1].value}</h6> : null }
                                    </div>
                                </div>
                                <div
                                    className="u-container-style u-layout-cell u-shape-rectangle u-size-30 u-layout-cell-2">
                                    <div className="u-container-layout u-valign-top u-container-layout-3">
                                        <h1 className="u-custom-font u-font-open-sans u-text u-text-default u-text-3">Attributes</h1>
                                        <p className="u-custom-font u-font-open-sans u-text u-text-default u-text-4">
                                            <p style={{margin: '0'}}>
                                                {attributesModelData.result && attributesModelData.result.attributes.map((attribute, index) =>
                                                    <p style={{margin: '0'}} key={index}>
                                                        {attribute.trait_type}: {attribute.value}
                                                    </p>
                                                )}
                                            </p>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="u-dialog-close-button u-icon u-text-grey-40 u-icon-1">
                    <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-efe9"></use>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"
                         xmlSpace="preserve" className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px"
                         id="svg-efe9"><rect x="7" y="0" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3138 8.0002)" width="2" height="16"></rect>
                        <rect x="0" y="7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3138 8.0002)" width="16"
                              height="2"></rect></svg>
                </button>
            </div>
        </section>
        <style> {`.u-dialog-section-4 {
            min - height: 500px;
        }

            .u-dialog-section-4 .u-dialog-1 {
                width: 800px;
                min-height: 542px;
                margin: 267px auto 49px;
            }

            .u-dialog-section-4 .u-container-layout-1 {
                padding: 30px;
            }

            .u-dialog-section-4 .u-layout-wrap-1 {
                margin - top: 0;
                margin-bottom: 0;
            }

            .u-dialog-section-4 .u-layout-cell-1 {
                min - height: 482px;
            }

            .u-dialog-section-4 .u-container-layout-2 {
                padding: 30px 28px;
            }

            .u-dialog-section-4 .u-text-1 {
                font - weight: 400;
                margin: 0 auto;
            }

            .u-dialog-section-4 .u-image-1 {
                width: 312px;
                height: 312px;
                margin: 10px auto 0;
            }

            .u-dialog-section-4 .u-text-2 {
                font - weight: 400;
                margin: 30px auto 0;
            }

            .u-dialog-section-4 .u-layout-cell-2 {
                min - height: 482px;
            }

            .u-dialog-section-4 .u-container-layout-3 {
                padding: 30px;
            }

            .u-dialog-section-4 .u-text-3 {
                font - weight: 400;
                font-size: 1.5rem;
                margin: 0 auto;
            }

            .u-dialog-section-4 .u-text-4 {
                font - weight: 400;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 0;
            }

            .u-dialog-section-4 .u-icon-1 {
                width: 20px;
                height: 20px;
            }

            @media (max-width: 1199px) {
                .u-dialog-section-4 .u-dialog-1 {
                height: auto;
            }

                .u-dialog-section-4 .u-layout-cell-1 {
                min-height: 397px;
            }

                .u-dialog-section-4 .u-layout-cell-2 {
                min-height: 397px;
            }
            }

            @media (max-width: 991px) {
                .u-dialog-section-4 .u-dialog-1 {
                width: 720px;
            }

                .u-dialog-section-4 .u-layout-cell-1 {
                min-height: 100px;
            }

                .u-dialog-section-4 .u-image-1 {
                width: 274px;
                height: 274px;
            }

                .u-dialog-section-4 .u-layout-cell-2 {
                min-height: 100px;
            }
            }

            @media (max-width: 767px) {
                .u-dialog-section-4 .u-dialog-1 {
                width: 540px;
            }

                .u-dialog-section-4 .u-container-layout-1 {
                padding-left: 10px;
                padding-right: 10px;
            }

                .u-dialog-section-4 .u-container-layout-2 {
                padding-left: 10px;
                padding-right: 10px;
            }

                .u-dialog-section-4 .u-container-layout-3 {
                padding-left: 10px;
                padding-right: 10px;
            }
            }

            @media (max-width: 575px) {
                .u-dialog-section-4 .u-dialog-1 {
                width: 340px;
            }
            } `}</style>
        <section
            className="u-black u-clearfix u-container-style u-dialog-block u-opacity u-opacity-70 u-dialog-section-5"
            id="carousel_81cb">
            <div
                className="u-border-4 u-border-palette-2-dark-1 u-container-style u-dialog u-palette-2-light-2 u-radius-26 u-shape-round u-dialog-1">
                <div className="u-container-layout u-valign-bottom u-container-layout-1">
                    <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
                        <div className="u-layout">
                            <div className="u-layout-row">
                                <div
                                    className="u-align-center u-container-style u-layout-cell u-size-60 u-layout-cell-1">
                                    <div className="u-container-layout u-container-layout-2">
                                        <h6 className="u-custom-font u-text u-text-default u-text-1"> Transfer your
                                            VeShark</h6>
                                        {transferModelData != null ? <img className="u-image u-image-default u-preserve-proportions u-image-1"
                                             src={metadata[transferModelData].img} alt="" data-image-width="1000"
                                             data-image-height="1000" /> : '' }
                                            <h6 className="u-custom-font u-text u-text-default u-text-2"> Disclaimer: {transferModelData}
                                                Only send your VNFT to a official VeChainThor network wallet like Sync 2
                                                or VeChainThor Mobile wallet</h6>
                                            <div className="u-form u-form-1">
                                                <form
                                                      className="u-clearfix u-form-spacing-10 u-form-vertical u-inner-form"
                                                      source="custom" name="form" style={{ padding: '10px'}}>
                                                    <div className="u-form-group u-form-name">
                                                        <label htmlFor="name-3d89" className="u-label">Wallet Address</label>
                                                        <input type="text" onChange={e => setRecipient(e.target.value)}
                                                               placeholder="Please enter a Valid VeChain Wallet Address"
                                                               id="name-3d89" name="Transfer"
                                                               className="u-border-1 u-border-grey-30 u-input u-input-rectangle u-white"
                                                               required="" />
                                                    </div>
                                                    <div className="u-align-center u-form-group u-form-submit">
                                                        <button onClick={async () => await nftContract.transfer(veSharksNftAbi, veSharksNftContract, recipient, metadata[transferModelData].result.edition, setLoading, loadAssets)}
                                                           className="u-border-none u-btn u-btn-submit u-button-style u-palette-2-dark-1 u-btn-1">Transfer<br />
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="u-dialog-close-button u-icon u-text-grey-40 u-icon-1" onClick={() => closeModal('carousel_81cb')}>
                    <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-efe9"></use>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"
                         xmlSpace="preserve" className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px"
                         id="svg-efe9"><rect x="7" y="0" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3138 8.0002)" width="2" height="16"></rect>
                        <rect x="0" y="7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3138 8.0002)" width="16"
                              height="2"></rect></svg>
                </button>
            </div>
        </section>
        <style> {`.u-dialog-section-5 {
            min - height: 500px;
        }

            .u-dialog-section-5 .u-dialog-1 {
                width: 800px;
                min-height: 542px;
                margin: 267px auto 49px;
            }

            .u-dialog-section-5 .u-container-layout-1 {
                padding: 30px;
            }

            .u-dialog-section-5 .u-layout-wrap-1 {
                margin - top: 0;
                margin-bottom: 0;
            }

            .u-dialog-section-5 .u-layout-cell-1 {
                min - height: 494px;
            }

            .u-dialog-section-5 .u-container-layout-2 {
                padding: 0 28px;
            }

            .u-dialog-section-5 .u-text-1 {
                font - family: "Alfa Slab One";
                margin: 9px auto 0;
            }

            .u-dialog-section-5 .u-image-1 {
                width: 237px;
                height: 237px;
                margin: 13px auto 0;
            }

            .u-dialog-section-5 .u-text-2 {
                font - family: "Alfa Slab One";
                margin: 9px 0 0;
            }

            .u-dialog-section-5 .u-form-1 {
                height: 154px;
                width: 570px;
                margin: 9px auto 0;
            }

            .u-dialog-section-5 .u-btn-1 {
                background - image: none;
            }

            .u-dialog-section-5 .u-icon-1 {
                width: 20px;
                height: 20px;
            }

            @media (max-width: 1199px) {
                .u-dialog-section-5 .u-dialog-1 {
                height: auto;
            }

                .u-dialog-section-5 .u-layout-cell-1 {
                min-height: 407px;
            }

                .u-dialog-section-5 .u-form-1 {
                width: 314px;
            }
            }

            @media (max-width: 991px) {
                .u-dialog-section-5 .u-dialog-1 {
                width: 720px;
            }

                .u-dialog-section-5 .u-layout-cell-1 {
                min-height: 100px;
            }

                .u-dialog-section-5 .u-form-1 {
                width: 274px;
            }
            }

            @media (max-width: 767px) {
                .u-dialog-section-5 .u-dialog-1 {
                width: 540px;
            }

                .u-dialog-section-5 .u-container-layout-1 {
                padding-left: 10px;
                padding-right: 10px;
            }

                .u-dialog-section-5 .u-container-layout-2 {
                padding-left: 10px;
                padding-right: 10px;
            }
            }

            @media (max-width: 575px) {
                .u-dialog-section-5 .u-dialog-1 {
                width: 340px;
            }
            }`}</style>
    </div>
};

export default Assets;
