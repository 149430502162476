const NODE = 'https://testnet.veblocks.net/'
const NETWORK = 'test'
const PROJECT_NAME = 'Sharks of Anarchy'
const VESHARKS_NFT_CONTRACT = '0xD1a411E11E760aF00E82227CB467C4DFe7667f71'
const ADDRESS_KEY = 'testnet_address';
//
// const NODE = 'https://mainnet.veblocks.net/'
// const NETWORK = 'main'
// const PROJECT_NAME = 'Sharks of Anarchy'
// const VESHARKS_NFT_CONTRACT = '0xD1a411E11E760aF00E82227CB467C4DFe7667f71'
// const ADDRESS_KEY = 'mainnet_address';

const USER_ADDRESS = window.localStorage.getItem(ADDRESS_KEY);

export {
    NODE,
    NETWORK,
    PROJECT_NAME,
    USER_ADDRESS,
    VESHARKS_NFT_CONTRACT,
    ADDRESS_KEY
}
