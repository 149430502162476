import Connex from '@vechain/connex'
import {
    NODE,
    NETWORK,
    VESHARKS_NFT_CONTRACT,
} from './'

const connex = new Connex({node: NODE, network: NETWORK})
const veSharksNftContract = connex.thor.account(VESHARKS_NFT_CONTRACT)

export {
    connex,
    veSharksNftContract,
}
