import sharksgif from '../images/sharks.gif'
import unionLogo from '../images/unionlogo.png'
import menu from "../images/menu.png";
import {USER_ADDRESS} from "../constants";
import logo from "../images/Logoooo.png";
import {handleLogIn, handleLogout} from "../components/functions/AccountFuntionality";
import {useEffect} from "react";

import('./../Home.css')
const Home = () => {
    useEffect(async () => {
        document.body.classList.add('u-overlap', 'u-overlap-transparent')
    }, [])
    return <div>
        <header className="u-clearfix u-header u-palette-2-base">
            <div className="u-clearfix u-sheet u-sheet-1">
                <nav className="u-menu u-menu-dropdown u-offcanvas u-menu-1 u-enable-responsive" data-responsive-from="XL">
                    <div className="menu-collapse" style={{ fontSize: '1rem', letterSpacing: '0px' }}>
                        <a className="u-button-style u-custom-left-right-menu-spacing u-custom-padding-bottom u-custom-text-active-color u-custom-text-color u-custom-text-hover-color u-custom-top-bottom-menu-spacing u-file-icon u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base u-text-white u-file-icon-1"
                           href="#">
                            <img src={menu}/>
                        </a>
                    </div>
                    <div className="u-nav-container">
                        <ul className="u-nav u-unstyled u-nav-1">
                            <li className="u-nav-item"><a
                                className="u-button-style u-nav-link u-text-active-white u-text-body-alt-color u-text-hover-white"
                                href="/" style={{padding: '10px 20px'}}>Home</a>
                            </li>
                            <li className="u-nav-item"><a
                                className="u-button-style u-nav-link u-text-active-white u-text-body-alt-color u-text-hover-white active"
                                href="./my-sharks" style={{padding: '10px 20px'}}>My Sharks</a>
                            </li>
                            <li className="u-nav-item"><a
                                className="u-button-style u-nav-link u-text-active-white u-text-body-alt-color u-text-hover-white"
                                href="./minting" style={{padding: '10px 20px'}}>Minting Page</a>
                            </li>
                        </ul>
                    </div>
                    <div className="u-nav-container-collapse">
                        <div
                            className="u-black u-container-style u-inner-container-layout u-opacity u-opacity-95 u-sidenav">
                            <div className="u-inner-container-layout u-sidenav-overflow">
                                <div className="u-menu-close"></div>
                                <ul className="u-align-center u-nav u-popupmenu-items u-unstyled u-nav-2">
                                    <li className="u-nav-item"><a className="u-button-style u-nav-link"
                                                                  href="./">Home</a>
                                    </li>
                                    <li className="u-nav-item"><a className="u-button-style u-nav-link active"
                                                                  href="./my-sharks">My Sharks</a>
                                    </li>
                                    <li className="u-nav-item"><a className="u-button-style u-nav-link"
                                                                  href="./minting">Minting Page</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="u-black u-menu-overlay u-opacity u-opacity-70"></div>
                    </div>
                    <style className="offcanvas-style"> {`.u-offcanvas
                    .u-sidenav {flex - basis: 250px !important;} .u-offcanvas:not(.u-menu-open-right)
                    .u-sidenav {margin - left: -250px;} .u-offcanvas.u-menu-open-right
                    .u-sidenav {margin - right: -250px;} @keyframes
                    menu-shift-left {from {left: 0;} to {left: 250px;}} @keyframes
                    menu-unshift-left {from {left: 250px;} to {left: 0;}} @keyframes
                    menu-shift-right {from {right: 0;} to {right: 250px;}} @keyframes
                    menu-unshift-right {from {right: 250px;} to {right: 0;}}`}            </style>
                </nav>
                {USER_ADDRESS === null ?
                    <button onClick={handleLogIn} className="u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-1">Connect Wallet</button> :
                    <button onClick={handleLogout} className="u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-1">{USER_ADDRESS.substr(0,4)}...{USER_ADDRESS.substr(38, 4)}</button> }

            </div>
        </header>
        <section className="u-clearfix u-image u-section-1" id="sec-e706" data-image-width="1500"
                 data-image-height="500">
            <div className="u-clearfix u-sheet u-sheet-1">
                <img className="u-image u-image-default u-preserve-proportions u-image-1" src={logo}
                     alt="" data-image-width="1500" data-image-height="500" />
            </div>
        </section>
        <section className="u-clearfix u-palette-2-light-1 u-section-2" id="sec-331c">
            <h1 className="u-align-center u-custom-font u-text u-title u-text-1">Meet The SHarks</h1>
            <div
                className="u-align-center u-border-4 u-border-palette-2-dark-1 u-container-style u-group u-palette-2-light-1 u-radius-29 u-shape-round u-group-1">
                <div className="u-container-layout u-container-layout-1">
                    <h2 className="u-custom-font u-text u-text-2">Introducing Sharks of Anarchy</h2>
                    <p className="u-custom-font u-text u-text-3">Sharks of Anarchy is a collection of 8000 Sharks of Anarchy swimming
                        around on the VeChainThor Blockchain. Keep an eye out for the special traits and collect 10
                        Sharks of Anarchy. To have a permanent income stream...<br />
                            <br />They will be very affordable since Whitelisted Members can mint for only 100 VET !<br />
                                <br />Missed out on the PreSale ? Even the Public sale will be very affordable ! Only 200
                                    VET per VeShark.<br />
                                        <br />Are you part of the Union or a x5 Bully holder?&nbsp;<br />This means you will
                                            be automatically Whitelisted !<br />
                                                <br />
                    </p>
                    <img className="u-image u-image-default u-preserve-proportions u-image-1" src={unionLogo}
                         alt="" data-image-width="512" data-image-height="512"/>
                </div>
            </div>
            <img
                className="u-border-4 u-border-palette-2-dark-1 u-image u-image-round u-preserve-proportions u-radius-29 u-image-2"
                src={sharksgif} alt="" data-image-width="1000" data-image-height="1000" />
                <a href="/minting"
                   className="u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-1">Straight
                    to mint</a>
                <a href="#sec-c297" data-page-id="696401292"
                   className="u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2">Learn
                    more</a>
        </section>
        <section className="u-clearfix u-palette-2-dark-1 u-section-3" id="sec-8b76">
            <div className="u-clearfix u-sheet u-sheet-1">
                <h1 className="u-custom-font u-text u-text-body-alt-color u-text-default u-text-1">Meet t​he Team</h1>
                <div className="u-expanded-width u-list u-list-1">
                    <div className="u-repeater u-repeater-1">
                        <div
                            className="u-align-center u-container-style u-list-item u-palette-2-light-2 u-radius-14 u-repeater-item u-shape-round u-list-item-1">
                            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-1">
                                <div alt="" className="u-image u-image-circle u-image-1" data-image-width="1000"
                                     data-image-height="1000"></div>
                                <h4 className="u-align-center u-text u-text-default u-text-2">Dr. EE Shark Father</h4>
                                <p className="u-text u-text-default u-text-palette-2-base u-text-3">Founder</p>
                                <p className="u-text u-text-4">I am just a man with a meme for a face.&nbsp;</p>
                                <div className="u-social-icons u-spacing-10 u-social-icons-1">
                                    <a className="u-social-url" target="_blank" href="https://twitter.com/elec_enginerd"
                                       title="Twitter"><span
                                        className="u-icon u-icon-circle u-social-icon u-social-twitter u-icon-1">
                    <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 112 112" ><use
                        xmlnsXlink="http://www.w3.org/1999/xlink"></use></svg>
                    <svg x="0px" y="0px" viewBox="0 0 112 112" id="svg-3fbc" className="u-svg-content"><path
                        d="M92.2,38.2c0,0.8,0,1.6,0,2.3c0,24.3-18.6,52.4-52.6,52.4c-10.6,0.1-20.2-2.9-28.5-8.2 c1.4,0.2,2.9,0.2,4.4,0.2c8.7,0,16.7-2.9,23-7.9c-8.1-0.2-14.9-5.5-17.3-12.8c1.1,0.2,2.4,0.2,3.4,0.2c1.6,0,3.3-0.2,4.8-0.7 c-8.4-1.6-14.9-9.2-14.9-18c0-0.2,0-0.2,0-0.2c2.5,1.4,5.4,2.2,8.4,2.3c-5-3.3-8.3-8.9-8.3-15.4c0-3.4,1-6.5,2.5-9.2 c9.1,11.1,22.7,18.5,38,19.2c-0.2-1.4-0.4-2.8-0.4-4.3c0.1-10,8.3-18.2,18.5-18.2c5.4,0,10.1,2.2,13.5,5.7c4.3-0.8,8.1-2.3,11.7-4.5 c-1.4,4.3-4.3,7.9-8.1,10.1c3.7-0.4,7.3-1.4,10.6-2.9C98.9,32.3,95.7,35.5,92.2,38.2z"></path></svg>
                  </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="u-align-center u-container-style u-list-item u-palette-2-light-2 u-radius-14 u-repeater-item u-shape-round u-list-item-2">
                            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-2">
                                <div alt="" className="u-image u-image-circle u-image-2" data-image-width="1000"
                                     data-image-height="1000"></div>
                                <h4 className="u-align-center u-text u-text-default u-text-5"> tyrolea</h4>
                                <p className="u-text u-text-default u-text-palette-2-base u-text-6">Co-Founder</p>
                                <p className="u-text u-text-7">Hiding from ya'll</p>
                                <div className="u-social-icons u-spacing-10 u-social-icons-2">
                                    <a className="u-social-url" target="_blank" href="https://twitter.com/elec_enginerd"
                                       title="twitter"><span
                                        className="u-icon u-icon-circle u-social-icon u-social-twitter u-icon-2">
                    <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 112 112" ><use
                        xmlnsXlink="http://www.w3.org/1999/xlink" ></use></svg>
                    <svg x="0px" y="0px" viewBox="0 0 112 112" id="svg-3fbc" className="u-svg-content"><path
                        d="M92.2,38.2c0,0.8,0,1.6,0,2.3c0,24.3-18.6,52.4-52.6,52.4c-10.6,0.1-20.2-2.9-28.5-8.2 c1.4,0.2,2.9,0.2,4.4,0.2c8.7,0,16.7-2.9,23-7.9c-8.1-0.2-14.9-5.5-17.3-12.8c1.1,0.2,2.4,0.2,3.4,0.2c1.6,0,3.3-0.2,4.8-0.7 c-8.4-1.6-14.9-9.2-14.9-18c0-0.2,0-0.2,0-0.2c2.5,1.4,5.4,2.2,8.4,2.3c-5-3.3-8.3-8.9-8.3-15.4c0-3.4,1-6.5,2.5-9.2 c9.1,11.1,22.7,18.5,38,19.2c-0.2-1.4-0.4-2.8-0.4-4.3c0.1-10,8.3-18.2,18.5-18.2c5.4,0,10.1,2.2,13.5,5.7c4.3-0.8,8.1-2.3,11.7-4.5 c-1.4,4.3-4.3,7.9-8.1,10.1c3.7-0.4,7.3-1.4,10.6-2.9C98.9,32.3,95.7,35.5,92.2,38.2z"></path></svg>
                  </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="u-clearfix u-palette-2-light-2 u-section-4" id="sec-c297">
            <div className="u-clearfix u-sheet u-sheet-1">
                <h1 className="u-align-center-md u-align-center-sm u-align-center-xs u-custom-font u-text u-text-body-alt-color u-text-default u-title u-text-1">Roadmap
                    2022</h1>
                <div className="u-expanded-width u-tabs u-tabs-1">
                    <ul className="u-palette-2-dark-2 u-tab-list u-unstyled u-tab-list-1" role="tablist">
                        <li className="u-tab-item" role="presentation">
                            <a className="active u-active-palette-2-light-1 u-button-style u-palette-2-dark-1 u-tab-link u-tab-link-1"
                               id="link-tab-bc3e" href="#tab-bc3e" role="tab" aria-controls="tab-bc3e"
                               aria-selected="true">Q3 2022</a>
                        </li>
                        <li className="u-tab-item" role="presentation">
                            <a className="u-active-palette-2-light-1 u-button-style u-palette-2-dark-1 u-tab-link u-tab-link-2"
                               id="link-tab-7d70" href="#tab-7d70" role="tab" aria-controls="tab-7d70"
                               aria-selected="false">Q4 2022</a>
                        </li>
                        <li className="u-tab-item" role="presentation">
                            <a className="u-active-palette-2-light-1 u-button-style u-palette-2-dark-1 u-tab-link u-tab-link-3"
                               id="link-tab-88dc" href="#tab-88dc" role="tab" aria-controls="tab-88dc"
                               aria-selected="false">Q1 2023</a>
                        </li>
                    </ul>
                    <div className="u-tab-content">
                        <div
                            className="u-align-center-lg u-align-center-md u-align-center-sm u-align-center-xs u-container-style u-palette-2-light-1 u-tab-active u-tab-pane u-tab-pane-1"
                            id="tab-bc3e" role="tabpanel" aria-labelledby="link-tab-bc3e">
                            <div className="u-container-layout u-valign-top u-container-layout-1">
                                <h1 className="u-custom-font u-text u-text-default u-text-2">July, August,
                                    September </h1>
                                <p className="u-align-center-xl u-text u-text-default u-text-3">- Start marketing
                                    campaign<br />- Opening of Discord Server<br />- Launch of website<br />- Several
                                        giveaways &amp; Promotional campaigns<br />- Pre Sale ( Union Members Whitelisted.
                                            )<br />- Public Sale<br />- VST Airdrop for all Shark Minters.
                                </p>
                            </div>
                        </div>
                        <div className="u-align-center u-container-style u-palette-2-light-1 u-tab-pane u-tab-pane-2"
                             id="tab-7d70" role="tabpanel" aria-labelledby="link-tab-7d70">
                            <div className="u-container-layout u-container-layout-2">
                                <h1 className="u-custom-font u-text u-text-4">October, November, December</h1>
                                <h1 className="u-custom-font u-text u-title u-text-5">- First Holder Reward
                                    distribution. ( aftermarket revenue )<br />- Sneak peak Meta V Sharks ( 3D )<br />-
                                        Several community Events<br />- First steps into integrating Sharks of Anarchy in Thugs
                                            Paradise<br />- Stake your Shark for VST<br />- WL Spots for other Sea Creatures<br />
                                                <br />More info coming soon !
                                </h1>
                            </div>
                        </div>
                        <div className="u-align-center u-container-style u-palette-2-light-1 u-tab-pane u-tab-pane-3"
                             id="tab-88dc" role="tabpanel" aria-labelledby="link-tab-88dc">
                            <div className="u-container-layout u-valign-middle u-container-layout-3">
                                <h1 className="u-custom-font u-text u-text-default u-text-6">To be disclosed...</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="u-align-center u-clearfix u-palette-2-light-1 u-section-5" id="sec-4be0">
            <div className="u-clearfix u-sheet u-sheet-1">
                <h2 className="u-custom-font u-text u-text-default u-text-1">Frequently Asked Questions</h2>
                <div className="u-border-3 u-border-grey-dark-1 u-line u-line-horizontal u-line-1"></div>
                <div className="u-accordion u-faq u-spacing-10 u-accordion-1">
                    <div className="u-accordion-item">
                        <a className="active u-accordion-link u-active-palette-2-dark-1 u-button-style u-custom-font u-font-open-sans u-hover-palette-2-dark-2 u-palette-2-dark-1 u-text-active-white u-text-body-alt-color u-text-hover-grey-25 u-accordion-link-1"
                           id="link-accordion-ae6a" aria-controls="accordion-ae6a" aria-selected="true">
                            <span className="u-accordion-link-text"> What is an NFT?</span><span
                            className="u-accordion-link-icon u-icon u-icon-circle u-text-palette-1-base u-icon-1"><svg
                            className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" ><use
                            xmlnsXlink="http://www.w3.org/1999/xlink"></use></svg><svg
                            className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-a823" ><path
                            d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
	c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z"></path></svg></span>
                        </a>
                        <div
                            className="u-accordion-active u-accordion-pane u-container-style u-palette-1-dark-1 u-accordion-pane-1"
                            id="accordion-ae6a" aria-labelledby="link-accordion-ae6a">
                            <div className="u-container-layout u-container-layout-1">
                                <div className="fr-view u-clearfix u-rich-text u-text">
                                    <p id="isPasted">
                                        <span className="u-custom-font u-font-open-sans"
                                              style={{fontFamily: 'Akaya Kanada'}}>NFT stands for "non-fungible token". An NFT is basically data that is accounted for in a digital ledger, and that data represents something specific and unique. An NFT can, for example, represent a unique piece of art or a game token. Because of this, NFTs have collectible value.</span>
                                    </p>
                                    <p>
                                        <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="u-accordion-item">
                        <a className="u-accordion-link u-active-palette-2-dark-1 u-button-style u-custom-font u-font-open-sans u-hover-palette-2-dark-2 u-palette-2-dark-1 u-text-active-white u-text-body-alt-color u-text-hover-grey-25 u-accordion-link-2"
                           id="link-accordion-8339" aria-controls="accordion-8339" aria-selected="false">
              <span className="u-accordion-link-text"> How can I buy a VeShark NFT?<br />
              </span><span className="u-accordion-link-icon u-icon u-icon-circle u-text-palette-1-base u-icon-2"><svg
                            className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" ><use
                            xmlnsXlink="http://www.w3.org/1999/xlink" ></use></svg><svg
                            className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-7d7e" ><path
                            d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
	c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z"></path></svg></span>
                        </a>
                        <div className="u-accordion-pane u-container-style u-palette-1-dark-1 u-accordion-pane-2"
                             id="accordion-8339" aria-labelledby="link-accordion-8339">
                            <div className="u-container-layout u-container-layout-2">
                                <div className="fr-view u-clearfix u-rich-text u-text">
                                    <p>
                                        <span className="u-custom-font"
                                              style={{fontFamily: 'Akaya Kanada'}}>The first step in purchasing and holding a VeShark NFT, is to create a VeChainThor wallet like Sync2/VeChainThor/Comet Wallet that you can connect to our Dapp.</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="u-accordion-item">
                        <a className="u-accordion-link u-active-palette-2-dark-1 u-button-style u-custom-font u-font-open-sans u-hover-palette-2-dark-2 u-palette-2-dark-1 u-text-active-white u-text-body-alt-color u-text-hover-grey-25 u-accordion-link-3"
                           id="link-accordion-1c17" aria-controls="accordion-1c17" aria-selected="false">
              <span className="u-accordion-link-text"> What blockchain will you be using?<br />
              </span><span className="u-accordion-link-icon u-icon u-icon-circle u-text-palette-1-base u-icon-3"><svg
                            className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16"><use
                            xmlnsXlink="http://www.w3.org/1999/xlink" ></use></svg><svg
                            className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-d58c" ><path
                            d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
	c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z"></path></svg></span>
                        </a>
                        <div className="u-accordion-pane u-container-style u-palette-1-dark-1 u-accordion-pane-3"
                             id="accordion-1c17" aria-labelledby="link-accordion-1c17">
                            <div className="u-container-layout u-container-layout-3">
                                <div className="fr-view u-clearfix u-rich-text u-text">
                                    <p id="isPasted">
                                        <span className="u-custom-font"
                                              style={{fontFamily: 'Akaya Kanada'}}>Sharks of Anarchy exists on the VeChain blockchain. The NFTs are minted, &nbsp;They cannot be found on any blockchain other than VeChain. Were you to discover someone posting a VeShark on a different blockchain or marketplace as their own work, please report it to the original creators.</span>
                                    </p>
                                    <p>
                                        <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="u-accordion-item">
                        <a className="u-accordion-link u-active-palette-2-dark-1 u-button-style u-custom-font u-font-open-sans u-hover-palette-2-dark-2 u-palette-2-dark-1 u-text-active-white u-text-body-alt-color u-text-hover-grey-25 u-accordion-link-4"
                           id="link-3c17" aria-controls="3c17" aria-selected="false">
                            <span className="u-accordion-link-text"> What does “MINT” mean?</span><span
                            className="u-accordion-link-icon u-icon u-icon-circle u-text-palette-1-base u-icon-4"><svg
                            className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16"><use
                            xmlnsXlink="http://www.w3.org/1999/xlink"></use></svg><svg
                            className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-c4ff"><path d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
	c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z"></path></svg></span>
                        </a>
                        <div className="u-accordion-pane u-container-style u-palette-1-dark-1 u-accordion-pane-4"
                             id="3c17" aria-labelledby="link-3c17">
                            <div className="u-container-layout u-container-layout-4">
                                <div className="fr-view u-clearfix u-rich-text u-text">
                                    <p>
                    <span className="u-custom-font" style={{fontFamily: 'Akaya Kanada'}}>“Minting” an NFT is, in more simple terms, uniquely publishing a token on the blockchain to make it purchasable. Each NFT minted will have its own unique “Mint number” that proves it is one of the collection.<br />
                        id="isPasted">
                    </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="u-accordion-item">
                        <a className="u-accordion-link u-active-palette-2-dark-1 u-button-style u-custom-font u-font-open-sans u-hover-palette-2-dark-2 u-palette-2-dark-1 u-text-active-white u-text-body-alt-color u-text-hover-grey-25 u-accordion-link-5"
                           id="link-7b2a" aria-controls="7b2a" aria-selected="false">
                            <span className="u-accordion-link-text"> How many NFTs will there be?</span><span
                            className="u-accordion-link-icon u-icon u-icon-circle u-text-palette-1-base u-icon-5"><svg
                            className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16"><use
                            xmlnsXlink="http://www.w3.org/1999/xlink"></use></svg><svg
                            className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-60fd"><path d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
	c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z"></path></svg></span>
                        </a>
                        <div className="u-accordion-pane u-container-style u-palette-1-dark-1 u-accordion-pane-5"
                             id="7b2a" aria-labelledby="link-7b2a">
                            <div className="u-container-layout u-container-layout-5">
                                <div className="fr-view u-clearfix u-rich-text u-text">
                                    <p>
                                        <span className="u-custom-font"
                                              style={{fontFamily: 'Akaya Kanada'}}>>At the time of release, there will be 5000 unique VeShark NFTs. This could expand or shrink in the Future....</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="u-accordion-item">
                        <a className="u-accordion-link u-active-palette-2-dark-1 u-button-style u-custom-font u-font-open-sans u-hover-palette-2-dark-2 u-palette-2-dark-1 u-text-active-white u-text-body-alt-color u-text-hover-grey-25 u-accordion-link-6"
                           id="link-b400" aria-controls="b400" aria-selected="false">
                            <span
                                className="u-accordion-link-text"> How much does it cost to buy a VeShark NFT?</span><span
                            className="u-accordion-link-icon u-icon u-icon-circle u-text-palette-1-base u-icon-6"><svg
                            className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" ><use
                            xmlnsXlink="http://www.w3.org/1999/xlink"></use></svg><svg
                            className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-6403"><path d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
	c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z"></path></svg></span>
                        </a>
                        <div className="u-accordion-pane u-container-style u-palette-1-dark-1 u-accordion-pane-6"
                             id="b400" aria-labelledby="link-b400">
                            <div className="u-container-layout u-container-layout-6">
                                <div className="fr-view u-clearfix u-rich-text u-text">
                                    <p>
                                        <span className="u-custom-font"
                                              style={{fontFamily: 'Akaya Kanada'}}>>When released, minting a VeShark costs 200 VET. After all Sharks of Anarchy are minted, the purchase price will depend on the floor price in the secondary market. In other words, the price of an individual VeShark NFT will be determined by collectors who choose to sell their Sharks.</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
};

export default Home;
