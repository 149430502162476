import {useState, useEffect, useRef} from 'react';
import {NftContract} from "../components/functions/NftContract";
import {veSharksNftAbi} from "../constants/veSharksNftAbi";
import {veSharksNftContract} from "../constants/contract";
import {Loader} from "../components/functions/Loader";
import menu from "../images/menu.png";
import shark from "../images/7239.png";
import {USER_ADDRESS} from "../constants";
import {handleLogIn, handleLogout} from "../components/functions/AccountFuntionality";

const Minting = () => {
    const [loading, setLoading] = useState(true);
    const totalSupply = useRef(0);
    const [availableTokenCount, setAvailableTokenCount] = useState(0);
    const currentMintPrice = useRef(0);
    const [mintAmount, setMintAmount] = useState(1);
    const [maxMintAmount, setMaxMintAmount] = useState(0);
    const [nftContract] = useState(new NftContract());

    useEffect(async () => {
        await initialize();
        setLoading(false)
    }, []);

    const initialize = async () => {
        totalSupply.current = await nftContract.totalSupply(veSharksNftAbi, veSharksNftContract);
        setAvailableTokenCount(await nftContract.availableTokenCount(veSharksNftAbi, veSharksNftContract));

        const saleInfo = await nftContract.saleInfo(veSharksNftAbi, veSharksNftContract);
        currentMintPrice.current = (parseInt(saleInfo['price']));
        if (saleInfo['saleType'] === '0') {
            setMaxMintAmount(await nftContract.whitelistMintAmount(veSharksNftAbi, veSharksNftContract));
        } else {
            setMaxMintAmount(saleInfo['maxTokens']);
        }
    }

    const mint = async () => {
        await nftContract.mint(veSharksNftAbi, veSharksNftContract, currentMintPrice.current, mintAmount, setLoading, initialize);
    }

    import('./../Minting.css')
    return <div>
        <header className="u-clearfix u-header u-palette-2-base u-header" id="sec-3903">
            <div className="u-clearfix u-sheet u-sheet-1">
                <nav className="u-menu u-menu-dropdown u-offcanvas u-menu-1" data-responsive-from="XL">
                    <div className="menu-collapse" style={{ fontSize: '1rem', letterSpacing: '0px' }}>
                        <a className="u-button-style u-custom-left-right-menu-spacing u-custom-padding-bottom u-custom-text-active-color u-custom-text-color u-custom-text-hover-color u-custom-top-bottom-menu-spacing u-file-icon u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base u-text-white u-file-icon-1"
                           href="#">
                            <img src={menu} alt="" />
                        </a>
                    </div>
                    <div className="u-nav-container">
                        <ul className="u-nav u-unstyled u-nav-1">
                            <li className="u-nav-item"><a
                                className="u-button-style u-nav-link u-text-active-white u-text-body-alt-color u-text-hover-white"
                                href="/" style={{padding: '10px 20px'}}>Home</a>
                            </li>
                            <li className="u-nav-item"><a
                                className="u-button-style u-nav-link u-text-active-white u-text-body-alt-color u-text-hover-white"
                                href="./my-sharks" style={{padding: '10px 20px'}}>My Sharks</a>
                            </li>
                            <li className="u-nav-item"><a
                                className="u-button-style u-nav-link u-text-active-white u-text-body-alt-color u-text-hover-white"
                                href="./minting" style={{padding: '10px 20px'}}>Minting Page</a>
                            </li>
                        </ul>
                    </div>
                    <div className="u-nav-container-collapse">
                        <div
                            className="u-black u-container-style u-inner-container-layout u-opacity u-opacity-95 u-sidenav">
                            <div className="u-inner-container-layout u-sidenav-overflow">
                                <div className="u-menu-close"></div>
                                <ul className="u-align-center u-nav u-popupmenu-items u-unstyled u-nav-2">
                                    <li className="u-nav-item"><a className="u-button-style u-nav-link"
                                                                  href="/" >Home</a>
                                    </li>
                                    <li className="u-nav-item"><a className="u-button-style u-nav-link"
                                                                  href="./my-sharks">My Sharks</a>
                                    </li>
                                    <li className="u-nav-item"><a className="u-button-style u-nav-link"
                                                                  href="./minting">Minting Page</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="u-black u-menu-overlay u-opacity u-opacity-70"></div>
                    </div>
                </nav>
                {USER_ADDRESS === null ?
                    <button onClick={handleLogIn} className="u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-1">Connect Wallet</button> :
                    <button onClick={handleLogout} className="u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-1">{USER_ADDRESS.substr(0,4)}...{USER_ADDRESS.substr(38, 4)}</button> }
            </div>
        </header>
        <section className="u-clearfix u-palette-2-light-1 u-section-1" id="sec-84f7">
            <div className="u-clearfix u-sheet u-sheet-1">
                <div className="u-clearfix u-expanded-width u-gutter-0 u-layout-wrap u-layout-wrap-1">
                    <div className="u-layout">
                        <div className="u-layout-row">
                            <div
                                className="u-align-center u-container-style u-custom-color-1 u-layout-cell u-left-cell u-size-30 u-size-xs-60 u-layout-cell-1"
                                src="">
                                <div className="u-container-layout u-container-layout-1">
                                    <h2 className="u-custom-font u-text u-text-1">Mint your VeShark</h2>
                                    <p className="u-custom-font u-font-open-sans u-text u-text-2">Sharks minted : {totalSupply.current - availableTokenCount} / {totalSupply.current}</p>
                                    <p className="u-custom-font u-font-open-sans u-text u-text-3"> The presale will
                                        start on the 13th of August 2022 at 12:00 pm UTC +0<br />The public sale will
                                            start on the 13th of August 2022 at 18:00 pm UTC +0
                                    </p>
                                    <p className="u-custom-font u-font-open-sans u-text u-text-4"> Price Pre-Sale (
                                        White Listed ) 100 VET<br />Price Public sale 200 VET
                                    </p>
                                    <p className="u-custom-font u-font-open-sans u-text u-text-5">Whitelist : Max 5 NFTs
                                        per wallet<br />Public sale : Max 10 NFTS per TX
                                    </p>
                                    <h6 className="u-custom-font u-font-open-sans u-text u-text-6">Amount you like to mint</h6>
                                    <input type="number" max={maxMintAmount} min={1} value={mintAmount} onChange={num=>setMintAmount(Number(num.target.value))}
                                           className="u-border-2 u-border-grey-dark-1 u-shape u-shape-rectangle u-white u-shape-1" />
                                    {/*<h6 className="u-custom-font u-font-open-sans u-text u-text-7">Whitelisted : No</h6>*/}
                                    <button onClick={mint}
                                       className="u-border-2 u-border-hover-grey-75 u-border-white u-btn u-button-style u-custom-font u-none u-text-hover-white u-btn-1">Mint</button>
                                    <p className="u-custom-font u-font-open-sans u-text u-text-8">After signing your TX.
                                        you can find your VeSharks NFT in the "<a href="My-Sharks.html"
                                                                                  data-page-id="324310310"
                                                                                  className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-2">My
                                            Sharks</a>" Tab in the menu.
                                    </p>
                                </div>
                            </div>
                            <div
                                className="u-container-style u-layout-cell u-right-cell u-size-30 u-size-xs-60 u-layout-cell-2"
                                src="">
                                <div className="u-container-layout u-container-layout-2" src="">
                                    <img
                                        className="u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-image u-image-1"
                                        src={shark} data-image-width="1000" data-image-height="1000" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
};

export default Minting;
